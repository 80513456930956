<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0 text-center">{{ tt('mcr_detail') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <h3>{{ tt('mcr_information') }}</h3>
                        </div>
                        <div  v-if="formtype == 'edit'" class="col-6 text-right">
                            <base-button size="sm" type="default" @click="editMcrInformation">{{ tt('edit') }}</base-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive-md table-bordered">
                                <tbody>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('subject') }}</th>
                                        <td colspan="3">{{ mcrInformation.subject }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('type') }}</th>
                                        <td>{{ mcrInformation.form_description }}</td>
                                        <th class="bg-secondary">{{ tt('created_by') }}</th>
                                        <td>{{ mcrInformation.created_by }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('status') }}</th>
                                        <td>{{ mcrInformation.status }}</td>
                                        <th class="bg-secondary">{{ tt('created_at') }}</th>
                                        <td>{{ mcrInformation.created_at }}</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>   
                    <br>
                    <div class="row">
                        <div class="col">
                            <h3>{{ tt('mcr_item') }}</h3>
                        </div>
                        <div v-if="formtype == 'view'" class="col text-right">
                          <base-button size="sm" type="default" @click="tracking()">{{
                            tt("tracking")
                          }}</base-button>
                        </div>
                        <div v-if="formtype == 'edit'" class="col text-right">
                          <base-button size="sm" type="default" @click="create('b2')">{{
                            tt("add_new")
                          }}</base-button>
                        </div>
                    </div>
                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="mcrItem">
                        <el-table-column :label="tt('no')" :prop="tt('no')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.id }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType != 'b6'" :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.material_number }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a1' || mcrType == 'a2' || mcrType == 'b1' || mcrType == 'b2' || mcrType == 'b3'" :label="tt('specification')" :prop="tt('specification')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.specification }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a3' || mcrType == 'b4'" :label="tt('note')" :prop="tt('note')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.note }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a4'" :label="tt('reason')" :prop="tt('reason')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.reason }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_from')" :prop="tt('consolidate_from')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_from }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_to')" :prop="tt('consolidate_to')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_to }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-update-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item v-if="formtype == 'view'" :command="{action:'detail', data:{btn:'detail', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('detail') }}</el-dropdown-item>
                                        <!-- <el-dropdown-item :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('edit') }}</el-dropdown-item> -->
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:'b2'}}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'remove', data:{id:row.id}}">{{ tt('delete') }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="card-footer pb-0 ">                           
                    <span class="float-right mt--4 mb-4">
                      Total Items {{total_mcr_item}}
                    </span>
                </div>
            </div>
            <div class="row">
                <div v-if="formtype == 'edit'" class="col text-center">
                    <router-link :to="'/material/draft-list'" class="btn btn-sm btn-dark">Draft List</router-link>  
                    <base-button size="sm" type="button" class="btn btn-lg btn-login" style="background: linear-gradient(to left,  #2e41db, #5626c7);padding-right: 40px;padding-left: 40px;color: #fff;outline-color: black;" v-on:click="sendMcr(mcr_code)" :disabled="btnSend.onLoading">
                      <span v-if="btnSend.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                      <span v-else>{{ tt('send') }}</span>
                    </base-button>
                </div>
            </div>
        </div>

        <!-- MODAL TRACKING  -->
        <tracking v-if="formTracking.show" form-show="formTracking.show" :mcr-code="mcr_code"/>
        
        <!--Form Edit Subject-->
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="formMcrInformation.show">
                <template slot="header">
                    <h5 class="modal-title">{{tt('edit_subject')}}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="mcrInformationEdit.new_subject" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formMcrInformation.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="updateMcrInformation" :disabled="btnUpdate.onLoading || invalid">
                        <span v-if="btnUpdate.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{ tt('update') }}
                        </span>
                    </base-button>
                </template>
            </modal>
        </validation-observer>
        <!--Form Edit Subject-->
        
        
        <modal :show.sync="form.b2.show" size="lg">
            <template slot="header">
                <h5 class="modal-title">{{tt('add_new')}}</h5>
            </template>

            <div class="card">
                <div class="card-header bg-primary">
                    <h4 class="text-white">{{ tt('create_new_material') }}</h4>
                </div>
                <div class="card-body">
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2">{{tt('basic_data_&_specification')}}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9">
                            <label class="form-control-label">{{ tt('item_name') }}<span class="text-danger">*</span></label>
                            <base-input :name="tt('item_name')" rules="required">
                                <el-autocomplete
                                class="inline-input col-md-12"
                                v-model="inputDraftListB2.item_name"
                                :fetch-suggestions="querySearchItemName"
                                :placeholder="tt('choose_item_name')"
                                :trigger-on-focus="false"
                                @select="handleSelect"
                                size="large"
                                ></el-autocomplete>

                                <!-- <el-select filterable class="select-danger" v-model="inputDraftListB2.item_name" @change="changeStandardItemNameB2" :placeholder="tt('choose_item_name')">
                                    <el-option class="select-danger" :value="sin.inc" :label="sin.item_name" :key="sin.inc" v-for="sin in  standardItemName"></el-option>
                                </el-select> -->
                            </base-input>
                        </div>
                        <div class="col-md-3">
                            <label class="form-control-label">{{ tt('item_type') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('item_type')" rules="required">
                                <el-select class="select-danger" v-model="inputDraftListB2.item_type_code" :placeholder="tt('choose_item_type')">
                                    <el-option class="select-danger" :value="it" :label="it" :key="it" v-for="it in  itemType"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('group_class') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('group_class')" rules="required">
                                <el-select class="select-danger" v-model="inputDraftListB2.group_class" :placeholder="tt('choose_item_type')">
                                    <el-option class="select-danger" :value="ngc.nsc" :label="ngc.description" :key="ngc.nsc" v-for="ngc in  natoGroupClass"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('uom') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('uom')" rules="required">
                                <el-select class="select-danger" v-model="inputDraftListB2.uom" :placeholder="tt('choose_unit_of_measure')">
                                    <el-option class="select-danger" :value="uom.code" :label="uom.code+' - '+uom.description" :key="uom.code" v-for="uom in  unitOfMeasure"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('material_type') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('material_type')" rules="required">
                                <el-select class="select-danger" v-model="inputDraftListB2.mtype_code" :placeholder="tt('choose_material_type')">
                                    <el-option class="select-danger" :value="mt.code" :label="mt.code+' - '+mt.description" :key="mt.code" v-for="mt in  materialType"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('catalog_type') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('catalog_type')" rules="required">
                                <el-select class="select-danger" v-model="inputDraftListB2.catalog_type" :placeholder="tt('choose_catalog_type')">
                                    <el-option class="select-danger" :value="ct.code" :label="ct.description" :key="ct.code" v-for="ct in  catalogType"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('material_group') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('material_group')" rules="required">
                                <el-select class="select-danger" v-model="inputDraftListB2.material_group" :placeholder="tt('choose_material_group')">
                                    <el-option class="select-danger" :value="mg.code" :label="mg.description" :key="mg.code" v-for="mg in  materialGroup"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('fabric_or_non_fabrication') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('fabric_or_non_fabrication')" rules="required">
                                <el-select class="select-danger" v-model="inputDraftListB2.fab_non_fab" :placeholder="tt('choose_fabric_or_non_fabrication')">
                                    <el-option class="select-danger" :value="fnf" :label="fnf" :key="fnf" v-for="fnf in  fabNonFab"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('scope_of_supply') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('scope_of_supply')" rules="required">
                                <el-select class="select-danger" v-model="inputDraftListB2.scope_of_supply" :placeholder="tt('choose_item_type')">
                                    <el-option class="select-danger" :value="sos.sos_header" :label="sos.sos_description" :key="sos.sos_header" v-for="sos in  scopeOfSupply"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('bom_or_non_bom') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('bom_or_non_bom')" rules="required">
                                <el-select class="select-danger" v-model="inputDraftListB2.bom_non_bom" :placeholder="tt('choose_bom_or_non_bom')">
                                    <el-option class="select-danger" :value="bnb" :label="bnb" :key="bnb" v-for="bnb in  bomNonBom"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>
                    <table class="table align-items-center table-flush table-bordered">
                        <thead class="card-header bg-primary">
                            <tr>
                                <th class="text-white">{{tt('characteristic')}}</th>
                                <th class="text-white">{{tt('value_view')}}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(dinc, key) in characteristicItemName" v-bind:key="dinc.cin_code">
                                <td>{{dinc.characteristic}}</td>
                                <td>
                                   <el-autocomplete
                                    v-model="inputDraftListB2.characteristics.value[key]"
                                    :fetch-suggestions="dinc.getValue"
                                    
                                    size="small"
                                    ></el-autocomplete>
                                </td>
                                <td><input type="checkbox" name="check"/></td>
                            </tr>
                        </tbody>
                    </table>
                    <label class="form-control-label mt-4">{{ tt('po_text_memo') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('po_text_memo')" rules="required">
                        <textarea class="form-control" rows="5" v-model="inputDraftListB2.po_text_memo"></textarea>
                    </base-input>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('manufacturer_part_number')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addMpnB2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <table class="table align-items-center table-flush table-bordered">
                        <thead class="card-header bg-primary">
                            <tr>
                                <th class="text-white">{{tt('manufacturer_code')}}</th>
                                <th class="text-white">{{tt('mpn')}}</th>
                                <th class="text-white">{{tt('type')}}</th>
                                <th class="text-white">{{tt('note')}}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(mpn, key) in manufacturePartNumberB2.manufacture_code" v-bind:key="mpn.manufacture_code">
                                <td>
                                    <select class="form-control-sm" v-model="manufacturePartNumberB2.manufacture_code[key]">
                                        <option value="">Choose Manufacture</option>
                                        <option :value="m.manufacture_code" v-for="m in manufacture" v-bind:key="m.id">{{m.manufacture_name}}</option>
                                    </select>
                                </td>
                                <td><input class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumberB2.mpn[key]" rules="required"></td>
                                <td>
                                    <select class="form-control-sm" v-model="manufacturePartNumberB2.manufacture_type[key]">
                                            <option value="">Choose Type</option>
                                        <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{mt.description}}</option>
                                    </select>
                                </td>
                                <td><input class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumberB2.manufacture_note[key]" rules="required"></td>
                                <td><i class="fa fa-trash text-danger" @click="removeMpnB2(key)"></i></td>
                            </tr>
                        </tbody>
                    </table>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('plant')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addPlB2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('plant')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(pl, key) in plantLevelB2.plant" v-bind:key="pl.plant">
                                    <td>
                                        <select class="form-control-sm" v-model="plantLevelB2.plant[key]">
                                            <option value="">Choose Plant</option>
                                            <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.plant_code">{{pc.description}}</option>
                                        </select>
                                    </td>
                                    <td><i class="fa fa-trash text-danger" @click="removePlB2(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('sales')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addSB2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('plant')}}</th>
                                    <th class="text-white">{{tt('sales_org')}}</th>
                                    <th class="text-white">{{tt('distr_channel')}}</th>
                                    <th class="text-white">{{tt('division')}}</th>
                                    <th class="text-white">{{tt('tax_cat')}}</th>
                                    <th class="text-white">{{tt('tax_class')}}</th>
                                    <th class="text-white">{{tt('gen_item_cat_grp')}}</th>
                                    <th class="text-white">{{tt('account_assign_grp')}}</th>
                                    <th class="text-white">{{tt('item_cat_grp')}}</th>
                                    <th class="text-white">{{tt('trans_group')}}</th>
                                    <th class="text-white">{{tt('loading_group')}}</th>
                                    <th class="text-white">{{tt('profit_center')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(s, key) in salesB2.sales_org" v-bind:key="s.sales_org">
                                    <td>
                                        <select class="form-control form-control-sm" v-model="salesB2.sls_plant_code[key]">
                                            <option value="">Choose Plant</option>
                                            <option :value="pl" v-for="pl in plantLevelB2.plant" v-bind:key="pl">{{pl}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control form-control-sm" v-model="salesB2.sales_org[key]">
                                            <option value="">Choose Sales Organization</option>
                                            <option :value="so.sales_org_code" v-for="so in salesOrganization" v-bind:key="so.sales_org_code">{{so.sales_org_description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB2.distr_channel[key]">
                                            <option value="">Choose Distribution Channel</option>
                                            <option :value="dc.distr_channel_code" v-for="dc in distributionChannel" v-bind:key="dc.distr_channel_code">{{dc.distr_channel_description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB2.division[key]">
                                            <option value="">Choose Division</option>
                                            <option :value="d.division_code" v-for="d in division" v-bind:key="d.division_code">{{d.division_desc}}</option>
                                        </select>
                                    </td>
                                    <td><input class="form-control form-control-sm" :name="tt('tax_cat')" :placeholder="tt('tax_cat')" v-model="salesB2.tax_cat[key]" rules="required"></td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB2.tax_class[key]">
                                            <option value="">Choose Tax Classification</option>
                                            <option :value="tc.tax_class_code" v-for="tc in taxClassification" v-bind:key="tc.tax_class_code">{{tc.tax_class_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB2.gen_item_cat_grp[key]">
                                            <option value="">Choose Item Category Group</option>
                                            <option :value="icg.item_cat_group_code" v-for="icg in itemCategoryGroup" v-bind:key="icg.item_cat_group_code">{{icg.item_cat_group_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB2.account_assign_grp[key]">
                                            <option value="">Choose Account Assignment Group</option>
                                            <option :value="aag.acc_ass_group_code" v-for="aag in accountAssignmentGroup" v-bind:key="aag.acc_ass_group_code">{{aag.acc_ass_group_code}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB2.item_cat_grp[key]">
                                            <option value="">Choose Item Category Group</option>
                                            <option :value="icg.item_cat_group_code" v-for="icg in itemCategoryGroup" v-bind:key="icg.item_cat_group_code">{{icg.item_cat_group_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB2.trans_group[key]">
                                            <option value="">Choose Transportation Group</option>
                                            <option :value="tg.trans_group_code" v-for="tg in transportationGroup" v-bind:key="tg.trans_group_code">{{tg.trans_group_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB2.loading_group[key]">
                                            <option value="">Choose Loading Group</option>
                                            <option :value="lg.loading_group_code" v-for="lg in loadingGroup" v-bind:key="lg.loading_group_code">{{lg.loading_group_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="salesB2.profit_center[key]">
                                            <option value="">Choose Profit Center</option>
                                            <option :value="pc.profit_center_code" v-for="pc in profitCenter" v-bind:key="pc.profit_center_code">{{pc.profit_center_description}}</option>
                                        </select>
                                    </td>
                                    <td><i class="fa fa-trash text-danger" @click="removeSB2(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('mrp')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addMrpB2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('plant')}}</th>
                                    <th class="text-white">{{tt('abc_indicator')}}</th>
                                    <th class="text-white">{{tt('mrp_type')}}</th>
                                    <th class="text-white">{{tt('mrp_controller')}}</th>
                                    <th class="text-white">{{tt('lot_size')}}</th>
                                    <th class="text-white">{{tt('min')}}</th>
                                    <th class="text-white">{{tt('max')}}</th>
                                    <th class="text-white">{{tt('procurement_type')}}</th>
                                    <th class="text-white">{{tt('special_proc')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(mrp, key) in mrpB2.abc_indicator" v-bind:key="mrp.abc_indicator">
                                    <td>
                                        <select class="form-control form-control-sm" v-model="mrpB2.plant_mrp[key]">
                                            <option value="">Choose Plant</option>
                                            <option :value="pl" v-for="pl in plantLevelB2.plant" v-bind:key="pl">{{pl}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="mrpB2.abc_indicator[key]">
                                            <option value="">Choose ABC Indicator</option>
                                            <option :value="ai.abc_indicator_code" v-for="ai in abcIndicator" v-bind:key="ai.abc_indicator_code">{{ai.abc_indicator_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="mrpB2.mrp_type[key]">
                                            <option value="">Choose MRP Type</option>
                                            <option :value="mt.mrp_type_code" v-for="mt in mrpTypes" v-bind:key="mt.mrp_type_code">{{mt.mrp_type_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="mrpB2.mrp_controller[key]">
                                            <option value="">Choose MRP Controller</option>
                                            <option :value="mc.mrp_controller_code" v-for="mc in mrpController" v-bind:key="mc.mrp_controller_code">{{mc.mrp_controller_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="mrpB2.lot_size[key]">
                                            <option value="">Choose Lot Size</option>
                                            <option :value="ls.lot_size_code" v-for="ls in lotSize" v-bind:key="ls.lot_size_code">{{ls.lot_size_desc}}</option>
                                        </select>
                                    </td>
                                    <td><input class="form-control form-control-sm" :name="tt('min')" :placeholder="tt('min')" v-model="mrpB2.min[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('max')" :placeholder="tt('max')" v-model="mrpB2.max[key]" rules="required"></td>
                                    <td>
                                        <select class="form-control-sm" v-model="mrpB2.procurement_type[key]">
                                            <option value="">Choose Procurement Type</option>
                                            <option :value="pt.proc_type_code" v-for="pt in procurementType" v-bind:key="pt.proc_type_code">{{pt.proc_type_desc}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="mrpB2.special_proc[key]">
                                            <option value="">Choose Special Procurement Type</option>
                                            <option :value="spt.spc_proc_type_code" v-for="spt in specialProcurementType" v-bind:key="spt.spc_proc_type_code">{{spt.spc_proc_type_desc}}</option>
                                        </select>
                                    </td>
                                    <td><i class="fa fa-trash text-danger" @click="removeMrpB2(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('accounting')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addAB2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('plant')}}</th>
                                    <th class="text-white">{{tt('valuation_class')}}</th>
                                    <th class="text-white">{{tt('valuation_category')}}</th>
                                    <th class="text-white">{{tt('valuation_type')}}</th>
                                    <th class="text-white">{{tt('price_control')}}</th>
                                    <th class="text-white">{{tt('price_determination')}}</th>
                                    <th class="text-white">{{tt('price_unit')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(vc, key) in accountingB2.valuation_class" v-bind:key="vc.code">
                                     <td>
                                        <select class="form-control form-control-sm" v-model="accountingB2.plant_accounting[key]">
                                            <option value="">Choose Plant</option>
                                            <option :value="pl" v-for="pl in plantLevelB2.plant" v-bind:key="pl">{{pl}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="accountingB2.valuation_class[key]">
                                            <option value="">Choose Valution Class</option>
                                            <option :value="vcl.code" v-for="vcl in valuationClass" v-bind:key="vcl.code">{{vcl.description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="accountingB2.valuation_category[key]">
                                            <option value="">Choose Valution Category</option>
                                            <option :value="vca.valuation_category_code" v-for="vca in valuationCategory" v-bind:key="vca.valuation_category_code">{{vca.valuation_category_description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="accountingB2.valuation_type[key]">
                                            <option value="">Choose Valution Type</option>
                                            <option :value="vt.valuation_type_code" v-for="vt in valuationType" v-bind:key="vt.valuation_type_code">{{vt.valuation_type_description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="accountingB2.price_control[key]">
                                            <option value="">Choose Price Control</option>
                                            <option :value="pc.price_control_code" v-for="pc in priceControl" v-bind:key="pc.price_control_code">{{pc.price_control_description}}</option>
                                        </select>
                                    </td>
                                    <td><input class="form-control form-control-sm" :name="tt('price_determination')" :placeholder="tt('price_determination')" v-model="accountingB2.price_determination[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('price_unit')" :placeholder="tt('price_unit')" v-model="accountingB2.price_unit[key]" rules="required"></td>
                                    <td><i class="fa fa-trash text-danger" @click="removeAB2(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('equipment_&_quantity_install')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addEqiB2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('plant')}}</th>
                                    <th class="text-white">{{tt('equipment_code')}}</th>
                                    <th class="text-white">{{tt('quantity_install')}}</th>
                                    <th class="text-white">{{tt('drawing_number')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(eqi, key) in equipmentQuantityInstallB2.equipment_code" v-bind:key="eqi.equipment_code">
                                    <td>
                                        <select class="form-control-sm" v-model="equipmentQuantityInstallB2.plant_equipment_code[key]">
                                            <option value="">Choose Plant</option>
                                            <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.description">{{pc.description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select class="form-control-sm" v-model="equipmentQuantityInstallB2.equipment_code[key]">
                                            <option value="">Choose Equipment Code</option>
                                            <option :value="ec.equipment_code" v-for="ec in equipmentCode" v-bind:key="ec.equipment_code">{{ec.equipment_name}}</option>
                                        </select>
                                    </td>
                                    <td><input class="form-control form-control-sm" :name="tt('qty_install')" :placeholder="tt('qty_install')" v-model="equipmentQuantityInstallB2.qty_installed[key]" rules="required"></td>
                                    <td><input class="form-control form-control-sm" :name="tt('drawing_number')" :placeholder="tt('drawing_number')" v-model="equipmentQuantityInstallB2.drawing_number[key]" rules="required"></td>
                                    <td><i class="fa fa-trash text-danger" @click="removeEqiB2(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('attachment_document')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addAdB2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('file_name')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(ad, key) in attachmentDocumentB2.file_attachment_code" v-bind:key="ad.file_name">
                                    <td><file-input :disabled="form.b2.disabled" @change="changeFileAttachmentNameB2" :initialLabel="ad.file_attachment_name" :ref="'file'+key"></file-input></td>  
                                    <td><i class="fa fa-trash text-danger" @click="removeAdB2(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <h4 class="bg-secondary border pt-2 pl-2 pb-2 pr-2 mb--1 mt-5">
                        <div class="row">
                            <div class="col-md-6">
                                {{tt('keyword')}}
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button size="sm" type="default" @click="addKB2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('keyword')}}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(k, key) in keywordB2.keyword" v-bind:key="k.keyword">
                                    <td><input class="form-control form-control-sm" :name="tt('file_name')" :placeholder="tt('manufacturer_code')" v-model="keywordB2.keyword[key]" rules="required"></td>
                                    <td><i class="fa fa-trash text-danger" @click="removeKB2(key)"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <label class="form-control-label mt-4">{{ tt('note') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('note')" rules="required">
                        <textarea class="form-control" rows="5" v-model="inputDraftListB2.note"></textarea>
                    </base-input>
                </div>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.b2.show = false">{{ tt('close') }}</base-button>
                <base-button v-if="form.b2.type == 'add'" type="primary" @click="saveB2('add')">{{ tt('add') }}</base-button>
                <base-button v-else-if="form.b2.type == 'edit'" type="primary" @click="saveB2('edit')">{{ tt('edit') }}</base-button>
            </template>
        </modal>
        
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialType from '@/services/master/materialType.service';
    import plantCode from '@/services/master/plantCode.service';
    import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import abcIndicator from '@/services/master/abcIndicator.service';
    import mrpTypes from '@/services/master/mrpTypes.service';
    import mrpController from '@/services/master/mrpController.service';
    import valuationClass from '@/services/master/valuationClass.service';
    import valuationCategory from '@/services/master/valuationCategory.service';
    import valuationType from '@/services/master/valuationType.service';
    import priceControl from '@/services/master/priceControl.service';
    import salesOrganization from '@/services/master/salesOrganization.service';
    import distributionChannel from '@/services/master/distributionChannel.service';
    import division from '@/services/master/division.service';
    import taxClassification from '@/services/master/taxClassification.service';
    import itemCategoryGroup from '@/services/master/itemCategoryGroup.service';
    import accountAssignmentGroup from '@/services/master/accountAssignmentGroup.service';
    import transportationGroup from '@/services/master/transportationGroup.service';
    import loadingGroup from '@/services/master/loadingGroup.service';
    import profitCenter from '@/services/master/profitCenter.service';
    import warehouse from '@/services/master/warehouse.service';
    import storageType from '@/services/master/storageType.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import locationCode from '@/services/master/locationCode.service';
    import catalogType from '@/services/master/catalogType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import characteristicItemName from '@/services/dictionary/characteristicItemName.service';
    import lotSize from '@/services/master/lotSize.service';
    import procurementType from '@/services/master/procurementType.service';
    import specialProcurementType from '@/services/master/specialProcurementType.service';
    import scopeOfSupply from '@/services/dictionary/scopeofSupply.service';
    import natoGroupClass from '@/services/dictionary/natoGroupClass.service';

    export default {        
        data() {
            return { 
                formTracking: {
                    show: false
                },
                loadTimeout: null,  
                btnUpdate: {
                    onLoading: false
                }, 
                btnSend: {
                    onLoading: false
                },        
                formMcrInformation: {
                    show: false
                },                
                form: {
                    b2: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    
                },
                formDetail: {
                    show: false
                },
                mcrType: 'a1',  
                inputDraftList: {

                },
                
                inputDraftListB2: {
                    prc_inc:'',
                    item_name: '',
                    item_type_code: '',
                    group_class: '',
                    uom: '',
                    material_type: '',
                    catalog_type: '',
                    material_group: '',
                    fab_non_fab: '',
                    scope_of_supply: '',
                    bom_non_bom: '',
                    note: '',
                    po_text_memo: '',
                    critical_non_critical: '',
                    characteristics: {
                        item_name: [],
                        value: [],
                        checkbox: []
                    }
                },
                
                draftList: {},
                mcrInformation: {},
                mcrInformationEdit: {
                    new_subject: ''
                },
                parameter: {},
                category: {},
                mcrItem: [],
                materialType: {},
                plantCode: {},
                unitOfMeasure: {},
                manufacture: {},
                manrefType: {},
                equipmentCode: {},
                abcIndicator: {},
                mrpTypes: {},
                mrpController: {},
                valuationClass: {},
                valuationCategory: {},
                valuationType: {},
                priceControl: {},
                salesOrganization: {},
                distributionChannel: {},
                division: {},
                taxClassification: {},
                itemCategoryGroup: {},
                accountAssignmentGroup: {},
                transportationGroup: {},
                loadingGroup: {},
                profitCenter: {},
                warehouse: {},
                storageType: {},
                materialNumber: {},
                locationCode: {},
                materialGroup: {},
                catalogType: {},
                itemType: {},
                fabNonFab: {},
                bomNonBom: {},
                drawingMaster: {},
                standardItemName: [],
                characteristicItemName: [],
                links:[],
                valitmnm:[],
                characteristicValue: [],
                lotSize: [],
                procurementType: [],
                specialProcurementType: [],
                scopeOfSupply: [],
                natoGroupClass: [],
                
                // manufacturePartNumber: {
                //     manufacture_code: [],
                //     mpn: [],
                //     manufacture_type: [],
                //     manufacture_note: []
                // },
               
                manufacturePartNumberB2: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                
                // equipmentQuantityInstall: {
                //     equipment_code: [],
                //     qty_installed: [],
                //     drawing_number: [],
                // },
                
                equipmentQuantityInstallB2: {
                    plant_equipment_code: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                // attachmentDocument: {
                //     file_name: [],
                //     file_type: [],
                // },
                // attachmentDocumentA1: {
                //     file_attachment_name: [],
                // },
                attachmentDocumentB2: {
                    file_attachment_code: [],
                },
                paramsB1: {
                    parameter: [],
                    category: [],
                    description: []
                },
                plantLevel: {
                    plant: []
                },
                plantLevelB2: {
                    plant: []
                },
                
                // storageLocation: {
                //     plant: [],
                //     storage_location: [],
                //     warehouse_number: [],
                //     storage_type: [],
                //     bin_location: [],
                // },
                // tempSales: {
                //     sales_org: '',
                //     distr_channel: '',
                //     division: '',
                //     tax_cat: '',
                //     tax_class: '',
                //     gen_item_cat_grp: '',
                //     account_assign_grp: '',
                //     item_cat_grp: '',
                //     trans_group: '',
                //     loading_group: '',
                //     profit_center: '',
                // },
                // sales: {
                //     sales_org: [],
                //     distr_channel: [],
                //     division: [],
                //     tax_cat: [],
                //     tax_class: [],
                //     gen_item_cat_grp: [],
                //     account_assign_grp: [],
                //     item_cat_grp: [],
                //     trans_group: [],
                //     loading_group: [],
                //     profit_center: [],
                // },
                salesB2: {
                    sls_plant_code:[],
                    sales_org: [],
                    distr_channel: [],
                    division: [],
                    tax_cat: [],
                    tax_class: [],
                    gen_item_cat_grp: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    trans_group: [],
                    loading_group: [],
                    profit_center: [],
                },
                
                // tempMrp: {
                //     abc_indicator: '',
                //     mrp_type: '',
                //     mrp_controller: '',
                //     lot_size: '',
                //     min: '',
                //     max: '',
                //     procurement_type: '',
                //     special_proc: '',
                // },
                // mrp: {
                //     plant_mrp:[],
                //     abc_indicator: [],
                //     mrp_type: [],
                //     mrp_controller: [],
                //     lot_size: [],
                //     min: [],
                //     max: [],
                //     procurement_type: [],
                //     special_proc: [],
                // },
                mrpB2: {
                    plant_mrp:[],
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                },
                
                // tempAccounting: {
                //     valuation_class: '',
                //     valuation_category: '',
                //     valuation_type: '',
                //     price_determination: '',
                //     price_unit: '',
                // },
                accounting_valuation_class: [],
                accounting_valuation_category: [],
                accounting_valuation_type: [],
                accounting_price_determination: [],
                accounting_price_unit: [],
                // accounting: {
                //     valuation_class: [],
                //     valuation_category: [],
                //     valuation_type: [],
                //     price_determination: [],
                //     price_unit: [],
                // },
                accountingB2: {
                    plant_accounting:[],
                    valuation_class: [],
                    valuation_category: [],
                    valuation_type: [],
                    price_control: [],
                    price_determination: [],
                    standard_price:[],
                    price_unit: [],
                },
                
                keywordB2: {
                    keyword: []
                },
                
                mcr_code: window.location.hash.split('/')[3],
                token: window.location.hash.split('/')[4],
                formtype: window.location.hash.split("/")[5],
                total_mcr_item: '',
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            
        },
        methods: {
            
            get() {
                let context = this;               
                Api(context, draftList.getMcrItem(context.mcr_code, context.token)).onSuccess(function(response) {                 
                    context.mcrItem = response.data.data.mcr_item;
                    context.mcrInformation = response.data.data.mcr[0];
                    context.parameter = response.data.data.parameter;
                    context.category = response.data.data.category;
                    context.itemType = response.data.data.item_type;
                    context.fabNonFab = response.data.data.fab_non_fab;
                    context.bomNonBom = response.data.data.bom_non_bom;
                    context.mcrInformationEdit.new_subject = response.data.data.mcr[0].subject;    
                    context.total_mcr_item = response.data.data.mcr_item.length;                  
                })
                .call()
            },
            getMaterialType() {
                let context = this;               
                Api(context, materialType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.materialType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialType = [];
                    }
                })
                .call()
            },
            getPlantCode() {
                let context = this;               
                Api(context, plantCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.plantCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCode = [];
                    }
                })
                .call()
            },
            getUnitOfMeasure() {
                let context = this;               
                Api(context, unitOfMeasure.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.unitOfMeasure = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.unitOfMeasure = [];
                    }
                })
                .call()
            },
            getManufacture() {
                let context = this;               
                Api(context, manufacture.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.manufacture = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manufacture = [];
                    }
                })
                .call()
            },
            getManrefType() {
                let context = this;               
                Api(context, manrefType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                })
                .call()
            },
            getEquipmentCode() {
                let context = this;               
                Api(context, equipmentCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.equipmentCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.equipmentCode = [];
                    }
                })
                .call()
            },
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicator = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.abcIndicator = [];
                    }
                })
                .call()
            },
            getMrpTypes() {
                let context = this;               
                Api(context, mrpTypes.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpTypes = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpTypes = [];
                    }
                })
                .call()
            },
            getMrpController() {
                let context = this;               
                Api(context, mrpController.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpController = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpController = [];
                    }
                })
                .call()
            },
            getValuationClass() {
                let context = this;               
                Api(context, valuationClass.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationClass = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationClass = [];
                    }
                })
                .call()
            },
            getValuationCategory() {
                let context = this;               
                Api(context, valuationCategory.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationCategory = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationCategory = [];
                    }
                })
                .call()
            },
            getValuationType() {
                let context = this;               
                Api(context, valuationType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationType = [];
                    }
                })
                .call()
            },
            getPriceControl() {
                let context = this;               
                Api(context, priceControl.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.priceControl = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.priceControl = [];
                    }
                })
                .call()
            },
            getSalesOrganization() {
                let context = this;               
                Api(context, salesOrganization.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.salesOrganization = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.salesOrganization = [];
                    }
                })
                .call()
            },
            getDistributionChannel() {
                let context = this;               
                Api(context, distributionChannel.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.distributionChannel = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.distributionChannel = [];
                    }
                })
                .call()
            },
            getDivision() {
                let context = this;               
                Api(context, division.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.division = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.division = [];
                    }
                })
                .call()
            },
            getTaxClassification() {
                let context = this;               
                Api(context, taxClassification.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.taxClassification = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.taxClassification = [];
                    }
                })
                .call()
            },
            getItemCategoryGroup() {
                let context = this;               
                Api(context, itemCategoryGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.itemCategoryGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.itemCategoryGroup = [];
                    }
                })
                .call()
            },
            getAccountAssignmentGroup() {
                let context = this;               
                Api(context, accountAssignmentGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.accountAssignmentGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.accountAssignmentGroup = [];
                    }
                })
                .call()
            },
            getTransportationGroup() {
                let context = this;               
                Api(context, transportationGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.transportationGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.transportationGroup = [];
                    }
                })
                .call()
            },
            getLoadingGroup() {
                let context = this;               
                Api(context, loadingGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.loadingGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.loadingGroup = [];
                    }
                })
                .call()
            },
            getProfitCenter() {
                let context = this;               
                Api(context, profitCenter.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.profitCenter = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.profitCenter = [];
                    }
                })
                .call()
            },
            getWarehouse() {
                let context = this;               
                Api(context, warehouse.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.warehouse = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.warehouse = [];
                    }
                })
                .call()
            },
            getStorageType() {
                let context = this;               
                Api(context, storageType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.storageType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.storageType = [];
                    }
                })
                .call()
            },
            getMaterialNumber() {
                let context = this;               
                Api(context, materialNumber.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.materialNumber = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialNumber = [];
                    }
                })
                .call()
            },
            getLocationCode() {
                let context = this;               
                Api(context, locationCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.locationCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.locationCode = [];
                    }
                })
                .call()
            },
            getCatalogType() {
                let context = this;               
                Api(context, catalogType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.catalogType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.catalogType = [];
                    }
                })
                .call()
            },
            getMaterialGroup() {
                let context = this;               
                Api(context, materialGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.materialGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialGroup = [];
                    }
                })
                .call()
            },
            getDrawingMaster() {
                let context = this;               
                Api(context, drawingMaster.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.drawingMaster = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.drawingMaster = [];
                    }
                })
                .call()
            },
            getStandardItemName() {
                let context = this;               
                Api(context, standardItemName.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.standardItemName = response.data.data.data.data;
                    var values = [];                                
                    response.data.data.data.data.forEach(function(value, j) {
                        values[j] = {
                            'link': value['inc'],
                            'value': value['item_name']
                        }
                    });
                    context.values = values;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.standardItemName = [];
                    }
                })
                .call()
            },
            getLotSize() {
                let context = this;               
                Api(context, lotSize.get()).onSuccess(function(response) {    
                    context.lotSize = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.lotSize = [];
                    }
                })
                .call()
            },
            getProcurementType() {
                let context = this;               
                Api(context, procurementType.get()).onSuccess(function(response) {    
                    context.procurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.procurementType = [];
                    }
                })
                .call()
            },
            getSpecialProcurementType() {
                let context = this;               
                Api(context, specialProcurementType.get()).onSuccess(function(response) {    
                    context.specialProcurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.specialProcurementType = [];
                    }
                })
                .call()
            },
            getScopeOfSupply() {
                let context = this;               
                Api(context, scopeOfSupply.get()).onSuccess(function(response) {    
                    context.scopeOfSupply = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.scopeOfSupply = [];
                    }
                })
                .call()
            },
            getNatoGroupClass() {
                let context = this;               
                Api(context, natoGroupClass.get()).onSuccess(function(response) {    
                    context.natoGroupClass = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.natoGroupClass = [];
                    }
                })
                .call()
            },
            handleTableAction(command) {
                this.formTracking.show = false;
                switch (command.action) {
                    case 'detail':
                            this.detail(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            detail(data) {
                let context = this;

                if (data.mcr_type == 'MCR01') {
                    data.mcr_type = 'a1';
                } else if (data.mcr_type == 'MCR02') {
                    data.mcr_type = 'a2';
                } else if (data.mcr_type == 'MCR03') {
                    data.mcr_type = 'a3';
                } else if (data.mcr_type == 'MCR04') {
                    data.mcr_type = 'a4';
                }

                if (data.mcr_type == 'a1') {
                    if (data.btn == 'detail') {
                        context.form.a1.show = true;
                        context.form.a1.disabled = true;
                        context.form.a1.type = 'detail';
                    } else {
                        context.form.a1.show = true;
                        context.form.a1.disabled = false;
                        context.form.a1.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListA1 = response.data.data[0];
                        context.inputDraftListA1.uom = response.data.data[0].uom_code;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA1 = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemMpn(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacturePartNumberA1.manufacture_code[i] = response.data.data[i].manufacture_code;
                            context.manufacturePartNumberA1.mpn[i] = response.data.data[i].mpn;
                            context.manufacturePartNumberA1.manufacture_type[i] = response.data.data[i].manufacture_type;
                            context.manufacturePartNumberA1.manufacture_note[i] = response.data.data[i].manufacture_note;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA1.manufacture_code = [];
                            context.manufacturePartNumberA1.mpn = [];
                            context.manufacturePartNumberA1.manufacture_type = [];
                            context.manufacturePartNumberA1.manufacture_note = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemEquipment(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentQuantityInstallA1.equipment_code[i] = response.data.data[i].equipment_code;
                            context.equipmentQuantityInstallA1.qty_installed[i] = response.data.data[i].qty_installed;
                            context.equipmentQuantityInstallA1.drawing_number[i] = response.data.data[i].drawing_number;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA1.equipment_code = [];
                            context.equipmentQuantityInstallA1.qty_installed = [];
                            context.equipmentQuantityInstallA1.drawing_number = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemAttachment(data.mcr_item_code)).onSuccess(function(response) {
                        context.attachmentDocumentA1.file_attachment_name = response.data.data;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.attachmentDocumentA1.file_attachment_name = [];
                        }
                    }).call()
                } else if (data.mcr_type == 'a2') {
                    if (data.btn == 'detail') {
                        context.form.a2.show = true;
                        context.form.a2.disabled = true;
                        context.form.a2.type = 'detail';
                    } else {
                        context.form.a2.show = true;
                        context.form.a2.disabled = false;
                        context.form.a2.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListA2 = response.data.data[0];
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA2 = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemMpn(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacturePartNumberA2.manufacture_code[i] = response.data.data[i].manufacture_code;
                            context.manufacturePartNumberA2.mpn[i] = response.data.data[i].mpn;
                            context.manufacturePartNumberA2.manufacture_type[i] = response.data.data[i].manufacture_type;
                            context.manufacturePartNumberA2.manufacture_note[i] = response.data.data[i].manufacture_note;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA2.manufacture_code = [];
                            context.manufacturePartNumberA2.mpn = [];
                            context.manufacturePartNumberA2.manufacture_type = [];
                            context.manufacturePartNumberA2.manufacture_note = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemEquipment(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentQuantityInstallA2.plant[i] = response.data.data[i].plant_code;
                            context.equipmentQuantityInstallA2.equipment_code[i] = response.data.data[i].equipment_code;
                            context.equipmentQuantityInstallA2.qty_installed[i] = response.data.data[i].qty_installed;
                            context.equipmentQuantityInstallA2.drawing_number[i] = response.data.data[i].drawing_number;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA2.plant = [];
                            context.equipmentQuantityInstallA2.equipment_code = [];
                            context.equipmentQuantityInstallA2.qty_installed = [];
                            context.equipmentQuantityInstallA2.drawing_number = [];
                        }
                    }).call()
                } else if (data.mcr_type == 'a3') {
                    if (data.btn == 'detail') {
                        context.form.a3.show = true;
                        context.form.a3.disabled = true;
                        context.form.a3.type = 'detail';
                    } else {
                        context.form.a3.show = true;
                        context.form.a3.disabled = false;
                        context.form.a3.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListA3 = response.data.data[0];                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA3 = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemMpn(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacturePartNumberA3.manufacture_code[i] = response.data.data[i].manufacture_code;
                            context.manufacturePartNumberA3.mpn[i] = response.data.data[i].mpn;
                            context.manufacturePartNumberA3.manufacture_type[i] = response.data.data[i].manufacture_type;
                            context.manufacturePartNumberA3.manufacture_note[i] = response.data.data[i].manufacture_note;
                        }                    
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA3.manufacture_code = [];
                            context.manufacturePartNumberA3.mpn = [];
                            context.manufacturePartNumberA3.manufacture_type = [];
                            context.manufacturePartNumberA3.manufacture_note = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemEquipment(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentQuantityInstallA3.plant[i] = response.data.data[i].plant_code;
                            context.equipmentQuantityInstallA3.equipment_code[i] = response.data.data[i].equipment_code;
                            context.equipmentQuantityInstallA3.qty_installed[i] = response.data.data[i].qty_installed;
                            context.equipmentQuantityInstallA3.drawing_number[i] = response.data.data[i].drawing_number;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA3.plant = [];
                            context.equipmentQuantityInstallA3.equipment_code = [];
                            context.equipmentQuantityInstallA3.qty_installed = [];
                            context.equipmentQuantityInstallA3.drawing_number = [];
                        }
                    }).call()
                } else if (data.mcr_type == 'a4') {
                    if (data.btn == 'detail') {
                        context.form.a4.show = true;
                        context.form.a4.disabled = true;
                        context.form.a4.type = 'detail';
                    } else {
                        context.form.a4.show = true;
                        context.form.a4.disabled = false;
                        context.form.a4.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListA4 = response.data.data[0];
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA4 = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemMpn(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacturePartNumberA4.mpn[i] = response.data.data[i].mpn;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA4.mpn = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemEquipment(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentQuantityInstallA4.equipment_code[i] = response.data.data[i].equipment_code;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA4.equipment_code = [];
                        }
                    }).call()
                } else if (data.mcr_type == 'b1') {
                    if (data.btn == 'detail') {
                        context.form.b1.show = true;
                        context.form.b1.disabled = true;
                        context.form.b1.type = 'detail';
                    } else {
                        context.form.b1.show = true;
                        context.form.b1.disabled = false;
                        context.form.b1.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListB1.id = response.data.data[0].id;
                        context.inputDraftListB1.description = response.data.data[0].specification;
                        context.inputDraftListB1.reason = response.data.data[0].reason;
                        context.inputDraftListB1.po_text_memo = response.data.data[0].note;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListB1 = [];
                        }
                    }).call()
                    
                    Api(context, draftList.getMcrItemParameter(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.paramsB1.parameter[i] = response.data.data[i].parameter;
                            context.paramsB1.category[i] = response.data.data[i].category;
                            context.paramsB1.description[i] = response.data.data[i].description;
                            // alert(response.data.data[i].parameter)
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.paramsB1.parameter = [];
                            context.paramsB1.category = [];
                            context.paramsB1.description = [];
                        }
                    }).call()

                    this.form.b1.show = true;
                } else if (data.mcr_type == 'b2') {
                    this.form.b2.show = true;
                } else if (data.mcr_type == 'b3') {
                    this.form.b3.show = true;
                } else if (data.mcr_type == 'b4') {
                    this.form.b4.show = true;
                } else if (data.mcr_type == 'b6') {
                    this.form.b6.show = true;
                }
            },
            remove(data) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        Api(context, draftList.deleteMcrItem(data.id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.get();
                        }).call();
                    }
                })
            },


            querySearchItemName(queryString, cb) {
                let context = this;
                if (queryString.length > 2) {
                    var links = context.values;
                    var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                    cb(results);
                }
            },
            
            createFilter(queryString) {
                return (link) => {
                return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            handleSelect(item) {
                this.inputDraftListB2.prc_inc = item.link;
                this.changeStandardItemNameB2();
                //console.log(this.inputDraftListB2.prc_inc);
            },

            create(key) {
                this.inputDraftListB2.item_name = '';
                this.inputDraftListB2.group_class ='';
                this.inputDraftListB2.uom ='';
                this.inputDraftListB2.material_type ='';
                this.inputDraftListB2.catalog_type ='';
                this.inputDraftListB2.material_group ='';
                this.inputDraftListB2.fab_non_fab ='';
                this.inputDraftListB2.scope_of_supply ='';
                this.inputDraftListB2.bom_non_bom ='';
                this.inputDraftListB2.item_type ='';
                this.inputDraftListB2.critical_non_critical ='';
                this.inputDraftListB2.characteristics = {
                    item_name: [],
                    value: [],
                    checkbox: []
                };

                this.getStandardItemName();
                this.getUnitOfMeasure();
                this.getMaterialType();
                this.getCatalogType();
                this.getMaterialGroup();
                this.getManufacture();
                this.getManrefType();
                this.getPlantCode();
                this.getSalesOrganization();
                this.getDistributionChannel();
                this.getDivision();
                this.getTaxClassification();
                this.getItemCategoryGroup();
                this.getAccountAssignmentGroup();
                this.getTransportationGroup();
                this.getLoadingGroup();
                this.getProfitCenter();
                this.getAbcIndicator();
                this.getMrpTypes();
                this.getMrpController();
                this.getLotSize();
                this.getProcurementType();
                this.getSpecialProcurementType();
                this.getValuationClass();
                this.getValuationCategory();
                this.getValuationType();
                this.getPriceControl();
                this.getEquipmentCode();
                this.getScopeOfSupply();
                this.getNatoGroupClass();

                this.form.b2.show = true;
            },
            
            addMpnB2() {
                if (this.manufacturePartNumberB2.manufacture_code.length == 0) {
                    this.manufacturePartNumberB2.manufacture_code.push('')
                    this.manufacturePartNumberB2.mpn.push('')
                    this.manufacturePartNumberB2.manufacture_type.push('')
                    this.manufacturePartNumberB2.manufacture_note.push('')
                } else if (this.manufacturePartNumberB2.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberB2.manufacture_code[this.manufacturePartNumberB2.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberB2.mpn[this.manufacturePartNumberB2.mpn.length-1] != '' &&
                        this.manufacturePartNumberB2.manufacture_type[this.manufacturePartNumberB2.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberB2.manufacture_note[this.manufacturePartNumberB2.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberB2.manufacture_code.push('')
                        this.manufacturePartNumberB2.mpn.push('')
                        this.manufacturePartNumberB2.manufacture_type.push('')
                        this.manufacturePartNumberB2.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnB2(key) {
                this.manufacturePartNumberB2.manufacture_code.splice(key, 1)
                this.manufacturePartNumberB2.mpn.splice(key, 1)
                this.manufacturePartNumberB2.manufacture_type.splice(key, 1)
                this.manufacturePartNumberB2.manufacture_note.splice(key, 1)
            },
            addMpnB3() {
                if (this.manufacturePartNumberB3.manufacture_code.length == 0) {
                    this.manufacturePartNumberB3.manufacture_code.push('')
                    this.manufacturePartNumberB3.mpn.push('')
                    this.manufacturePartNumberB3.manufacture_type.push('')
                    this.manufacturePartNumberB3.manufacture_note.push('')
                } else if (this.manufacturePartNumberB3.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberB3.manufacture_code[this.manufacturePartNumberB3.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberB3.mpn[this.manufacturePartNumberB3.mpn.length-1] != '' &&
                        this.manufacturePartNumberB3.manufacture_type[this.manufacturePartNumberB3.manufacture_type.length-1] != '' &&
                        this.manufacturePartNumberB3.manufacture_note[this.manufacturePartNumberB3.manufacture_note.length-1] != '') {
                        
                        this.manufacturePartNumberB3.manufacture_code.push('')
                        this.manufacturePartNumberB3.mpn.push('')
                        this.manufacturePartNumberB3.manufacture_type.push('')
                        this.manufacturePartNumberB3.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            addMpn() {
                this.manufacturePartNumber.manufacture_code.push('')
                this.manufacturePartNumber.mpn.push('')
                this.manufacturePartNumber.manufacture_type.push('')
                this.manufacturePartNumber.manufacture_note.push('')
            },
            removeMpn(key) {
                this.manufacturePartNumber.manufacture_code.splice(key, 1)
                this.manufacturePartNumber.mpn.splice(key, 1)
                this.manufacturePartNumber.manufacture_type.splice(key, 1)
                this.manufacturePartNumber.manufacture_note.splice(key, 1)
            },
            addEqi() {
                this.equipmentQuantityInstall.equipment_code.push('')
                this.equipmentQuantityInstall.qty_installed.push('')
                this.equipmentQuantityInstall.drawing_number.push('')
            },
            removeEqi(key) {
                this.equipmentQuantityInstall.equipment_code.splice(key, 1)
                this.equipmentQuantityInstall.qty_installed.splice(key, 1)
                this.equipmentQuantityInstall.drawing_number.splice(key, 1)
            },
            
            addEqiB2() {
                if (this.equipmentQuantityInstallB2.plant_equipment_code.length == 0) {
                    this.equipmentQuantityInstallB2.plant_equipment_code.push('')
                    this.equipmentQuantityInstallB2.equipment_code.push('')
                    this.equipmentQuantityInstallB2.qty_installed.push('')
                    this.equipmentQuantityInstallB2.drawing_number.push('')
                } else if (this.equipmentQuantityInstallB2.plant_equipment_code.length > 0) {
                    if (this.equipmentQuantityInstallB2.plant_equipment_code[this.equipmentQuantityInstallB2.plant_equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallB2.equipment_code[this.equipmentQuantityInstallB2.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallB2.qty_installed[this.equipmentQuantityInstallB2.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallB2.drawing_number[this.equipmentQuantityInstallB2.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallB2.plant_equipment_code.push('')
                        this.equipmentQuantityInstallB2.equipment_code.push('')
                        this.equipmentQuantityInstallB2.qty_installed.push('')
                        this.equipmentQuantityInstallB2.drawing_number.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiB2(key) {
                this.equipmentQuantityInstallB2.plant_equipment_code.splice(key, 1)
                this.equipmentQuantityInstallB2.equipment_code.splice(key, 1)
                this.equipmentQuantityInstallB2.qty_installed.splice(key, 1)
                this.equipmentQuantityInstallB2.drawing_number.splice(key, 1)                
            },
            
            addAdB2() {
                if (this.attachmentDocumentB2.file_attachment_code.length == 0) {
                    this.attachmentDocumentB2.file_attachment_code.push('')
                } else if (this.attachmentDocumentB2.file_attachment_code.length > 0) {
                    if (this.attachmentDocumentB2.file_attachment_code[this.attachmentDocumentB2.file_attachment_code.length-1] == '') {
                        this.$notify({
                            message: 'Please choose image',                  
                            type: 'danger'
                        });
                    } else {
                        this.attachmentDocumentB2.file_attachment_code.push('')
                    }
                }
            },
            removeAdB2(key) {
                this.attachmentDocumentB2.file_attachment_code.splice(key, 1)
            },
            addAd() {
                this.attachmentDocumentA1.file_attachment_name.push('')
            },
            removeAd(key) {
                this.attachmentDocumentA1.file_attachment_name.splice(key, 1)
            },
            addKB2() {
                if (this.keywordB2.keyword.length == 0) {
                    this.keywordB2.keyword.push('')
                } else if (this.keywordB2.keyword.length > 0) {
                    if (this.keywordB2.keyword[this.keywordB2.keyword.length-1] == '') {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    } else {
                        this.keywordB2.keyword.push('')
                    }
                }
            },
            removeKB2(key) {
                this.keywordB2.keyword.splice(key, 1)
            },
            addPl() {
                this.plantLevel.plant.push('')
            },
            removePl(key) {
                this.plantLevel.plant.splice(key, 1)
            },
            addPlB2() {
                if (this.plantLevelB2.plant.length == 0) {
                    this.plantLevelB2.plant.push('')
                } else if (this.plantLevelB2.plant.length > 0) {
                    if (this.plantLevelB2.plant[this.plantLevelB2.plant.length-1] != '') {
                        this.plantLevelB2.plant.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removePlB2(key) {
                this.plantLevelB2.plant.splice(key, 1)
            },
            addPlB3() {
                if (this.plantLevelB3.plant.length == 0) {
                    this.plantLevelB3.plant.push('')
                } else if (this.plantLevelB3.plant.length > 0) {
                    if (this.plantLevelB3.plant[this.plantLevelB3.plant.length-1] != '') {
                        this.plantLevelB3.plant.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removePlB3(key) {
                this.plantLevelB3.plant.splice(key, 1)
            },
            changeStandardItemNameB2() {                
                let context = this;               
                Api(context, characteristicItemName.get(null,{search: context.inputDraftListB2.prc_inc})).onSuccess(function(response) {    
                    context.characteristicItemName = response.data.data.data;
                    context.inputDraftListB2.characteristics.value = [];
                    var dcin = response.data.data.data;
                    dcin.forEach(function(cin, i) {           
                        cin.getValue = function(queryString, cb) {  
                            Api(context, characteristicItemName.get_characteristic_value(cin.cin_code, {search_char_value: queryString})).onSuccess(function(response) {                                            
                                var values = [];                                
                                response.data.data.data.data.forEach(function(value, j) {
                                    values[j] = {
                                        'code': value['characteristic_value'],
                                        'value': value['characteristic_value']
                                    }
                                });                                                                               
                                cb(values);
                            }).call();       
                        }     
                        context.$set(characteristicItemName,i, cin);
                        
                    });


                    for (var i=0; i<response.data.data.length; i++) {
                        let context2 = this;               
                        context.characteristicValue[i] = [];
                        Api(context2, characteristicItemName.get_characteristic_value(response.data.data[i].cin_code)).onSuccess(function(response2) {    
                            for (var j=0; j<response2.data.data.data.data.length; j++) {
                                context.characteristicValue[i][j] = [];
                                context.characteristicValue[i][j] = response2.data.data.data.data[j];
                            }
                        }).onError(function(error) {                    
                        }).call()

                    }
                    console.log(response.data.data)
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.characteristicItemName = [];
                    }
                }).call()                
            },
            
           
            addMrpB2() {
                if (this.mrpB2.abc_indicator.length == 0) {
                    this.mrpB2.abc_indicator.push('')
                    this.mrpB2.mrp_type.push('')
                    this.mrpB2.mrp_controller.push('')
                    this.mrpB2.lot_size.push('')
                    this.mrpB2.min.push('')
                    this.mrpB2.max.push('')
                    this.mrpB2.procurement_type.push('')
                    this.mrpB2.special_proc.push('')
                    this.mrpB2.plant_mrp.push('')
                } else if (this.mrpB2.abc_indicator.length > 0) {
                    if (this.mrpB2.abc_indicator[this.mrpB2.abc_indicator.length-1] != '' &&
                        this.mrpB2.mrp_type[this.mrpB2.mrp_type.length-1] != '' &&
                        this.mrpB2.mrp_controller[this.mrpB2.mrp_controller.length-1] != '' &&
                        this.mrpB2.lot_size[this.mrpB2.lot_size.length-1] != '' &&
                        this.mrpB2.min[this.mrpB2.min.length-1] != '' &&
                        this.mrpB2.max[this.mrpB2.max.length-1] != '' &&
                        this.mrpB2.procurement_type[this.mrpB2.procurement_type.length-1] != '' &&
                        this.mrpB2.special_proc[this.mrpB2.special_proc.length-1] != '') {
                        
                        this.mrpB2.abc_indicator.push('')
                        this.mrpB2.mrp_type.push('')
                        this.mrpB2.mrp_controller.push('')
                        this.mrpB2.lot_size.push('')
                        this.mrpB2.min.push('')
                        this.mrpB2.max.push('')
                        this.mrpB2.procurement_type.push('')
                        this.mrpB2.special_proc.push('')
                        this.mrpB2.plant_mrp.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMrpB2(key) {
                this.mrpB2.abc_indicator.splice(key, 1)
                this.mrpB2.mrp_type.splice(key, 1)
                this.mrpB2.mrp_controller.splice(key, 1)
                this.mrpB2.lot_size.splice(key, 1)
                this.mrpB2.min.splice(key, 1)
                this.mrpB2.max.splice(key, 1)
                this.mrpB2.procurement_type.splice(key, 1)
                this.mrpB2.special_proc.splice(key, 1)
                this.mrpB2.plant_mrp.push('')
            },
            
            addAB2() {
                if (this.accountingB2.valuation_class.length == 0) {
                    this.accountingB2.valuation_class.push('')
                    this.accountingB2.valuation_category.push('')
                    this.accountingB2.valuation_type.push('')
                    this.accountingB2.price_control.push('')
                    this.accountingB2.price_determination.push('')
                    this.accountingB2.price_unit.push('')
                    this.accountingB2.plant_accounting.push('')
                } else if (this.accountingB2.valuation_class.length > 0) {
                    if (this.accountingB2.valuation_class[this.accountingB2.valuation_class.length-1] != '' &&
                        this.accountingB2.valuation_category[this.accountingB2.valuation_category.length-1] != '' &&
                        this.accountingB2.valuation_type[this.accountingB2.valuation_type.length-1] != '' &&
                        this.accountingB2.price_control[this.accountingB2.price_control.length-1] != '' &&
                        this.accountingB2.price_determination[this.accountingB2.price_determination.length-1] != '' &&
                        this.accountingB2.price_unit[this.accountingB2.price_unit.length-1] != '') {
                        
                        this.accountingB2.valuation_class.push('')
                        this.accountingB2.valuation_category.push('')
                        this.accountingB2.valuation_type.push('')
                        this.accountingB2.price_control.push('')
                        this.accountingB2.price_determination.push('')
                        this.accountingB2.price_unit.push('')
                        this.accountingB2.plant_accounting.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeAB2(key) {
                this.accountingB2.valuation_class.splice(key, 1)
                this.accountingB2.valuation_category.splice(key, 1)
                this.accountingB2.valuation_type.splice(key, 1)
                this.accountingB2.price_control.splice(key, 1)
                this.accountingB2.price_determination.splice(key, 1)
                this.accountingB2.price_unit.splice(key, 1)
                this.accountingB2.plant_accounting.splice(key, 1)
            },
            
            addSB2() {
                if (this.salesB2.sales_org.length == 0) {
                    this.salesB2.sales_org.push('')
                    this.salesB2.distr_channel.push('')
                    this.salesB2.division.push('')
                    this.salesB2.tax_cat.push('')
                    this.salesB2.tax_class.push('')
                    this.salesB2.gen_item_cat_grp.push('')
                    this.salesB2.account_assign_grp.push('')
                    this.salesB2.item_cat_grp.push('')
                    this.salesB2.trans_group.push('')
                    this.salesB2.loading_group.push('')
                    this.salesB2.profit_center.push('')
                    this.salesB2.sls_plant_code.push('')
                } else if (this.salesB2.sales_org.length > 0) {
                    if (this.salesB2.sales_org[this.salesB2.sales_org.length-1] != '' &&
                        this.salesB2.distr_channel[this.salesB2.distr_channel.length-1] != '' &&
                        this.salesB2.division[this.salesB2.division.length-1] != '' &&
                        this.salesB2.tax_cat[this.salesB2.tax_cat.length-1] != '' &&
                        this.salesB2.tax_class[this.salesB2.tax_class.length-1] != '' &&
                        this.salesB2.gen_item_cat_grp[this.salesB2.gen_item_cat_grp.length-1] != '' &&
                        this.salesB2.account_assign_grp[this.salesB2.account_assign_grp.length-1] != '' &&
                        this.salesB2.item_cat_grp[this.salesB2.item_cat_grp.length-1] != '' &&
                        this.salesB2.trans_group[this.salesB2.trans_group.length-1] != '' &&
                        this.salesB2.loading_group[this.salesB2.loading_group.length-1] != '' &&
                        this.salesB2.profit_center[this.salesB2.trans_group.length-1] != '') {
                        
                        this.salesB2.sales_org.push('')
                        this.salesB2.distr_channel.push('')
                        this.salesB2.division.push('')
                        this.salesB2.tax_cat.push('')
                        this.salesB2.tax_class.push('')
                        this.salesB2.gen_item_cat_grp.push('')
                        this.salesB2.account_assign_grp.push('')
                        this.salesB2.item_cat_grp.push('')
                        this.salesB2.trans_group.push('')
                        this.salesB2.loading_group.push('')
                        this.salesB2.profit_center.push('')
                        this.salesB2.sls_plant_code.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }                
            },
            removeSB2(key) {
                this.salesB2.sales_org.splice(key, 1)
                this.salesB2.distr_channel.splice(key, 1)
                this.salesB2.division.splice(key, 1)
                this.salesB2.tax_cat.splice(key, 1)
                this.salesB2.tax_class.splice(key, 1)
                this.salesB2.gen_item_cat_grp.splice(key, 1)
                this.salesB2.account_assign_grp.splice(key, 1)
                this.salesB2.item_cat_grp.splice(key, 1)
                this.salesB2.trans_group.splice(key, 1)
                this.salesB2.loading_group.splice(key, 1)
                this.salesB2.profit_center.splice(key, 1)
                this.salesB2.sls_plant_code.splice(key, 1)
            },
            
            // addA() {                
            //     if (this.tempAccounting.valuation_class != '' && 
            //         this.tempAccounting.valuation_category != '' && 
            //         this.tempAccounting.valuation_type != '' && 
            //         this.tempAccounting.price_determination != '' && 
            //         this.tempAccounting.price_unit) {

            //         this.accounting.valuation_class.push(this.tempAccounting.valuation_class)
            //         this.accounting.valuation_category.push(this.tempAccounting.valuation_category)
            //         this.accounting.valuation_type.push(this.tempAccounting.valuation_type)
            //         this.accounting.price_determination.push(this.tempAccounting.price_determination)
            //         this.accounting.price_unit.push(this.tempAccounting.price_unit)

            //     } else {
            //         this.$notify({
            //             message: 'Please complete data!',                  
            //             type: 'danger'
            //         });
            //     }
            // },
            // removeA(key) {
            //     this.accounting.valuation_class.splice(key, 1)
            //     this.accounting.valuation_category.splice(key, 1)
            //     this.accounting.valuation_type.splice(key, 1)
            //     this.accounting.price_determination.splice(key, 1)
            //     this.accounting.price_unit.splice(key, 1)
            // },
            editMcrInformation() {
                this.formMcrInformation.add = false;
                this.formMcrInformation.show = true;
            }, 
            updateMcrInformation() {
                let context = this;     
                // context.errorMessage = null;      
                context.btnUpdate.onLoading = true;    
                Api(context, draftList.updateMcrSubject(context.mcrInformation.mcr_code, context.mcrInformation.token, context.mcrInformationEdit)).onSuccess(function(response) {
                    context.mcrInformation.subject = context.mcrInformationEdit.new_subject;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success'
                    });
                }).onFinish(function() {
                    context.btnUpdate.onLoading = false;
                    context.formMcrInformation.show = false;
                }).call()        
            },
            
            changeFileAttachmentNameB2(files) {
                this.attachmentDocumentB2.file_attachment_code[this.attachmentDocumentB2.file_attachment_code.length-1] = files[0]                
            },
            saveB2(save) {
                let context = this;
                let formData = new FormData();

                if (context.inputDraftListB2.item_name != '' &&
                    context.inputDraftListB2.item_type_code != '' &&
                    context.inputDraftListB2.group_class != '' &&
                    context.inputDraftListB2.uom_code != '' &&
                    context.inputDraftListB2.mtype_code != '' &&
                    context.inputDraftListB2.catalog_type != '' &&
                    context.inputDraftListB2.mgroup_code != '' &&
                    context.inputDraftListB2.fab_non_fab != '' &&
                    context.inputDraftListB2.scope_of_supply != '' &&
                    context.inputDraftListB2.bom_non_bom != '' &&
                    context.inputDraftListB2.note != '') {

                    formData.append('mcr_code', context.mcr_code)
                    formData.append('prc_inc', context.inputDraftListB2.prc_inc)
                    formData.append('prc_item_type_code', context.inputDraftListB2.item_type_code)
                    formData.append('prc_nsc', context.inputDraftListB2.group_class)
                    formData.append('prc_uom_code', context.inputDraftListB2.uom)
                    formData.append('prc_mtype_code', context.inputDraftListB2.mtype_code)
                    formData.append('prc_catalog_type_code', context.inputDraftListB2.catalog_type)
                    formData.append('prc_mgroup_code', context.inputDraftListB2.material_group)
                    formData.append('prc_fab_non_fab', context.inputDraftListB2.fab_non_fab)
                    formData.append('prc_sos_header', '')
                    formData.append('prc_bom_non_bom', context.inputDraftListB2.bom_non_bom)
                    formData.append('prc_po_text_memo', context.inputDraftListB2.po_text_memo)
                    formData.append('note', context.inputDraftListB2.note)

                    for (var i=0; i<context.manufacturePartNumberB2.manufacture_code.length; i++) {
                        if (context.manufacturePartNumberB2.manufacture_code[i] != '' &&
                            context.manufacturePartNumberB2.mpn[i] != '' &&
                            context.manufacturePartNumberB2.manufacture_type[i] != '' &&
                            context.manufacturePartNumberB2.manufacture_note[i] != '') {
                           
                            formData.append('prc_manufacture_code['+i+']', context.manufacturePartNumberB2.manufacture_code[i])
                            formData.append('prc_mpn['+i+']', context.manufacturePartNumberB2.mpn[i])
                            formData.append('prc_manufacture_type['+i+']', context.manufacturePartNumberB2.manufacture_type[i])
                            formData.append('prc_manufacture_note['+i+']', context.manufacturePartNumberB2.manufacture_note[i])
                        } 
                        // else {
                        //     context.$notify({
                        //         message: 'Please complete data!',                  
                        //         type: 'danger'
                        //     });
                        //     return false;
                        // }
                    }
                        
                    for (var i=0; i<context.plantLevelB2.plant.length; i++) {
                        if (context.plantLevelB2.plant[i] != '') {
                            formData.append('prc_plant_code['+i+']', context.plantLevelB2.plant[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.salesB2.sales_org.length; i++) {
                        if (context.salesB2.sales_org[i] != '' &&
                            context.salesB2.distr_channel[i] != '' &&
                            context.salesB2.division[i] != '' &&
                            context.salesB2.tax_class[i] != '' &&
                            context.salesB2.tax_class[i] != '' &&
                            context.salesB2.gen_item_cat_grp[i] != '' &&
                            context.salesB2.account_assign_grp[i] != '' &&
                            context.salesB2.item_cat_grp[i] != '' &&
                            context.salesB2.trans_group[i] != '' &&
                            context.salesB2.loading_group[i] != '' &&
                            context.salesB2.profit_center[i] != '') {
                            
                            formData.append('prc_plant_sales['+i+']', context.salesB2.sls_plant_code[i])
                            formData.append('prc_sales_org_code['+i+']', context.salesB2.sales_org[i])
                            formData.append('prc_distr_channel_code['+i+']', context.salesB2.distr_channel[i])
                            formData.append('prc_division_code['+i+']', context.salesB2.division[i])
                            formData.append('prc_tax_cat_code['+i+']', context.salesB2.tax_class[i])
                            formData.append('prc_tax_class_code['+i+']', context.salesB2.tax_class[i])
                            formData.append('prc_gen_item_cat_grp_code['+i+']', context.salesB2.gen_item_cat_grp[i])
                            formData.append('prc_acc_ass_grp_code['+i+']', context.salesB2.account_assign_grp[i])
                            formData.append('prc_trans_group_code['+i+']', context.salesB2.trans_group[i])
                            formData.append('prc_loading_group_code['+i+']', context.salesB2.loading_group[i])
                            formData.append('prc_profit_center_code['+i+']', context.salesB2.profit_center[i])
                        }
                        //  else {
                        //     context.$notify({
                        //         message: 'Please complete data!',                  
                        //         type: 'danger'
                        //     });
                        //     return false;
                        // }
                    }
                        
                    for (var i=0; i<context.mrpB2.abc_indicator.length; i++) {
                        if (context.mrpB2.abc_indicator[i] != '' &&
                            context.mrpB2.mrp_type[i] != '' &&
                            context.mrpB2.mrp_controller[i] != '' &&
                            context.mrpB2.lot_size[i] != '' &&
                            context.mrpB2.min[i] != '' &&
                            context.mrpB2.max[i] != '' &&
                            context.mrpB2.procurement_type[i] != '' &&
                            context.mrpB2.special_proc[i] != '') {

                            formData.append('prc_plant_mrp['+i+']', context.mrpB2.plant_mrp[i])
                            formData.append('prc_abc_indicator_code['+i+']', context.mrpB2.abc_indicator[i])
                            formData.append('prc_mrp_type_code['+i+']', context.mrpB2.mrp_type[i])
                            formData.append('prc_mrp_controller_code['+i+']', context.mrpB2.mrp_controller[i])
                            formData.append('prc_lot_size_code['+i+']', context.mrpB2.lot_size[i])
                            formData.append('prc_min_stock_code['+i+']', context.mrpB2.min[i])
                            formData.append('prc_max_stock_code['+i+']', context.mrpB2.max[i])
                            formData.append('prc_procurement_type_code['+i+']', context.mrpB2.procurement_type[i])
                            formData.append('prc_special_proc_type_code['+i+']', context.mrpB2.special_proc[i])
                        } 
                        // else {
                        //     context.$notify({
                        //         message: 'Please complete data!',                  
                        //         type: 'danger'
                        //     });
                        //     return false;
                        // }
                    }
                        
                    for (var i=0; i<context.accountingB2.valuation_class.length; i++) {
                        if (context.accountingB2.valuation_class[i] != '' &&
                            context.accountingB2.valuation_category[i] != '' &&
                            context.accountingB2.valuation_type[i] != '' &&
                            context.accountingB2.price_control[i] != '' &&
                            context.accountingB2.price_determination[i] != '' &&
                            context.accountingB2.price_unit[i] != '') {

                            formData.append('prc_plant_accounting['+i+']', context.accountingB2.plant_accounting[i])
                            formData.append('prc_valuation_class_code['+i+']', context.accountingB2.valuation_class[i])
                            formData.append('prc_valuation_category_code['+i+']', context.accountingB2.valuation_category[i])
                            formData.append('prc_valuation_type_code['+i+']', context.accountingB2.valuation_type[i])
                            formData.append('prc_price_control_code['+i+']', context.accountingB2.price_control[i])
                            formData.append('prc_price_determination_code['+i+']', context.accountingB2.price_determination[i])
                            formData.append('prc_price_unit['+i+']', context.accountingB2.price_unit[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                        
                    for (var i=0; i<context.equipmentQuantityInstallB2.plant_equipment_code.length; i++) {
                        if (context.equipmentQuantityInstallB2.plant_equipment_code[i] != '' &&
                            context.equipmentQuantityInstallB2.equipment_code[i] != '' &&
                            context.equipmentQuantityInstallB2.qty_installed[i] != '' &&
                            context.equipmentQuantityInstallB2.drawing_number[i] != '') {
                            formData.append('prc_plant_equipment['+i+']', context.equipmentQuantityInstallB2.plant_equipment_code[i])
                            formData.append('prc_equipment_code['+i+']', context.equipmentQuantityInstallB2.equipment_code[i])
                            formData.append('prc_qty_installed['+i+']', context.equipmentQuantityInstallB2.qty_installed[i])
                            formData.append('prc_drawing_number['+i+']', context.equipmentQuantityInstallB2.drawing_number[i])
                        } 
                        // else {
                        //     context.$notify({
                        //         message: 'Please complete data!',                  
                        //         type: 'danger'
                        //     });
                        //     return false;
                        // }
                    }
                        
                    for (var i=0; i<context.attachmentDocumentB2.file_attachment_code.length; i++) {
                        if (context.attachmentDocumentB2.file_attachment_code.length > 0 &&
                            context.attachmentDocumentB2.file_attachment_code[context.attachmentDocumentB2.file_attachment_code.length-1] != '') {
                            formData.append('file_attachment_code['+i+']', context.attachmentDocumentB2.file_attachment_code[i])
                        } 
                        // else {
                        //     context.$notify({
                        //         message: 'Please complete data!',                  
                        //         type: 'danger'
                        //     });
                        //     return false;
                        // }
                    }
                        
                    for (var i=0; i<context.keywordB2.keyword.length; i++) {
                        if (context.keywordB2.keyword[i] != '') {
                            formData.append('keyword['+i+']', context.keywordB2.keyword[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                    
                    let api = '';
                    console.log(formData)
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItem(formData));
                    } else {
                        api = Api(context, draftList.updateMcrItem(formData, context.inputDraftListB1.id));
                    }
                    
                    api.onSuccess(function(response) {    
                        context.get();
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success'
                        });
                        context.form.b2.show = false;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                    return false;
                }                
            },
            tracking() {
                this.formTracking.show = false;
                this.formTracking.show = true;
            },
            sendMcr(mcr_code) {
              this.btnSend.onLoading = true;
              let api = "";
              let context = this;
              api = Api(context, draftList.sendMcr(mcr_code));
                api.onSuccess(function(response) {
                  context.$notify({
                    message: response.data.message,
                    type: "success"
                  });
                  context.$router.push('/material/draft-list');
                })
                .onFinish(function() {
                  context.btnSend.onLoading = false;
                })
                .call();
            },
            
        }   
    };
</script>
<style></style>